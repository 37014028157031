import Typed from 'typed.js';

let typed = '';
let updateTypedString = '';
let typedCount = 0;

const data = new FormData();

data.append( 'action', 'get_user_geo' );

fetch('/wp-admin/admin-ajax.php', {
  method: "POST",
  credentials: 'same-origin',
  body: data,
})
.then((res) => res.json())
.then((data) => {
  window.userIP = data.user_ip;

  const cityInput = document.querySelector('input[name="city"]');

  if ( cityInput ) {
    cityInput.value = data.user_city;
  }

  if ( data.user_country == 'US' || data.user_country == 'CA' ) {
    const regionInputWrap = document.querySelector('.js-country-us-ca');
    const regionInput = document.querySelector('input[name="region"]');
    const regionInputCol = document.querySelector('.js-country-us-ca-col');

    if ( regionInputWrap ) {
      regionInputWrap.classList.remove('hidden');
    }

    if ( regionInputCol ) {
      regionInputCol.className += ' lg:w-1/2';
    }

    if ( regionInput ) {
      regionInput.value = data.user_region;
    }
  }

  updateTypedString = () => {
    if ( typedCount < 1 && data.user_location_us != 'the United States' ) {
      document.querySelector('.invisible').classList.remove('invisible');
      typed.strings = [data.user_location_us];
      typed.reset();
      // $('.typed-cursor').addClass('invisible');
    }
    typedCount++ ;
  }
});

const userLocationText = document.querySelector('.js-user-location-us');

  //typed js
if ( userLocationText ) {
  typed = new Typed('.js-user-location-us', {
    strings: ['the United States'],
    typeSpeed: 90,
    onComplete: function() {
      document.querySelector('.typed-cursor').className += ' invisible';
      updateTypedString();
    },
  });
}
